module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":690},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sheriff's Handywork","short_name":"Sheriff's Handywork","description":"Honest, reliable handyman with over 20 years experience providing bathroom and kitchen installation, painting, decorating, flooring, gardening, home repairs, appliance installation, odd jobs, and maintenance services to London and areas within the M25. Get in touch for a no-obligation quote today!","start_url":"","background_color":"#e0e0e0","theme_color":"#121212","display":"minimal-ui","icons":[{"src":"/sh_favicon.png","sizes":"48x48","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
